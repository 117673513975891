<template>
    <div>
        <!-- 算命 -->
        <div class="banner_background">
            <div class="banner">
                <div class="kun"></div>
                <!--<img class="banner_img" src="@/assets/home/banner.png">-->
                <div class="banner_form">
                    <el-form ref="msg" :model="msg">
                        <el-form-item label="">
                            <a class="form_a">Name:</a><br>
                            <el-input v-model="msg.name" placeholder="your name" class="input001"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <a class="form_a">Sex:</a><br>
                            <el-radio-group v-model="msg.sex">
                                <el-radio label="0">
                                    <a class="form_radio">Male</a>
                                </el-radio>
                                <el-radio label="1">
                                    <a class="form_radio">Female</a>
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="">
                            <a class="form_a">Date of birth:</a>
                            <div class="form1">
                                <el-input  v-model="msg.day" placeholder="day" class="input002"></el-input>
                                <el-input v-model="msg.month" placeholder="month" class="input003"></el-input>
                                <el-input v-model="msg.year" placeholder="year" class="input004"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="">
                            <a class="form_a">Time of birth:</a>
                            <div class="form1">
                                <el-input v-model="msg.hour" placeholder="hour"  class="input005"></el-input>
                                <el-input v-model="msg.minute" placeholder="minute"  class="input006"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <button class="form_button" type="button" @click="postData(this)">Discover your luck</button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>

        <div id="modal" class="modal" @click="hideModal()">
            <img id="modal-image" src="@/assets/home/fu.jpg" class="modal-image">
        </div>


        <div style="width: 96%; margin-left: 2%">
            <!--Find what you're looking for -->
            <div>
                <div class="lookf">
                    <div class="lookf_a">
                        <a class="title_a" style="color: #000;">Find what you're looking for</a>
                    </div>
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div v-for="item in swiperList" :key="item.id" class="swiper-slide"
                                :style="`background-image:url(${item.imgUrl})`">
                                <!--<h3>{{ item.title }}</h3>-->
                            </div>
                        </div>
                        <!-- 指示器 -->
                        <!--<div class="swiper-pagination"></div>-->
                        <!-- 左右按钮 -->
                        <!--<div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>-->
                    </div>
                </div>
            </div>
            <!-- Home Decor -->
            <div>
                <div class="lookf">
                    <div class="lookf_a">
                        <a class="title_a" style="color: #000;">Home Decor</a>
                    </div>
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div v-for="item in tableData" :key="item.id" class="swiper-slide"
                                :style="`background-image:url(${item.img})`">
                            </div>
                        </div>
                        <!-- 指示器 -->
                        <!--<div class="swiper-pagination"></div>-->
                        <!-- 左右按钮 -->
                        <!--<div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>-->
                    </div>
                </div>
            </div>

            <!-- Taoism -->
            <div class="taoism">
                <div style="display: flex">
                    <div style="text-align: center;">
                        <div class="mtop80">
                            <a class="title_a70">Taoism</a>
                        </div>
                        <div class="mtop60">
                            <p class="taoism_p">Rooted in ancient Chinese wisdom.</p>
                        </div>
                        <div class="mtop30">
                            <p class="taoism_p">Taoism offers aunique perspective on life, nature, and the interconnectedness of all things.</p>
                        </div>
                    </div>
                    <img class="taoism_img" src="@/assets/home/Taoism.png">
                </div>

            </div>

            <!-- Maoshan Talismans -->
            <div>
                <div class="maoshan">
                    <div style="width: 62%; margin-left: 19%">
                        <div>
                            <a class="title_a">Maoshan Talismans</a>
                        </div>
                        <div class="mtop70">
                            <p class="maoshan_p">Maoshan talismans are a Taoist form of magic.</p>
                        </div>
                        <div class="mtop40">
                            <p class="maoshan_p">"Talismans" are considered the decrees of the heavenly gods.</p>
                        </div>
                        <div class="mtop40">
                            <p class="maoshan_p">They can summon deities, expelevilspirits, subdue demons, and cure diseases and calamities.</p>
                        </div>
                    </div>
                    <img class="maoshan_img" src="@/assets/home/Maoshan_Talismans.png">
                </div>  
            </div>
            

            <!-- From Our Community -->
            <div class="foc">
                <div style="text-align: center">
                    <a class="title_a40">From Our Community</a>
                </div>
                <div class="mtop30">
                    <div class="swiper-container1">
                        <div class="swiper-wrapper">
                            <div v-for="item in swiperList" :key="item.id" class="swiper-slide"
                                >
                                <img class="type1_img" :src="`${item.imgUrl}`">
                                <!--<h3>{{ item.title }}</h3>-->
                            </div>
                        </div>
                            <!-- 指示器 -->
                            <!--<div class="swiper-pagination"></div>-->
                            <!-- 左右按钮 -->
                            <!--<div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div>-->
                    </div>
                    <!--<div class="type1">
                        <img class="type1_img" src="@/assets/home/Exorcism.png">
                    </div>
                    <div class="type1">
                        <img class="type1_img" src="@/assets/home/Love.png">
                    </div>
                    <div class="type1">
                        <img class="type1_img" src="@/assets/home/Wealth.png">
                    </div>
                    <div class="type1">
                        <img class="type1_img" src="@/assets/home/Health.png">
                    </div>
                    <div class="type1">
                        <img class="type1_img" src="@/assets/home/Studies.png">
                    </div>-->
                </div>
            </div>
        </div>
      <!--<div style="width: 1200px; margin: 0 0 0 0; margin: auto">
        <el-carousel height="220px" :interval="5000" > 
          <el-carousel-item v-for="item in imgs1" :key="item">
            <img :src="item" alt="" style="width: 100%">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="margin: 50px 0">
          <el-row>
              <el-col :span="12">
                  <div style="margin-top: 30px;margin-left: 20px; width: 520px; height: 400px">
                      <el-carousel height="360px" width="500px" :interval="5000">
                          <el-carousel-item v-for="item in imgs2" :key="item">
                              <img :src="item" alt="" style="width: 100%">
                          </el-carousel-item>
                      </el-carousel>
                  </div>
              </el-col>
              <el-col :span="12">
                  <el-card style="margin: 10px 0; width: 570px; height: 400px">
                      <div style="padding-bottom: 20px; font-size: 20px">系 统 公 告</div>
                      <el-collapse accordion v-model="activeNames" v-for="(item, index) in notice">
                          <el-collapse-item :title="item.name" :name="index + ''">
                              <template slot="title">
                                  <span style="">{{item.time}}</span>
                                  <span style="font-size: 15px; margin-left: 20px">{{item.name}}</span>
                              </template>
                              <div v-html="item.content"></div>
                          </el-collapse-item>
                      </el-collapse>
                  </el-card>
              </el-col>
          </el-row>
      </div>-->


        <!-- 商品卡片 -->
      <!-- <div style="margin-top: 50px">
          <el-row :gutter="5">
              <el-col :span="5" v-for="item in tableData" :key="item.id" style="margin-bottom: 20px; margin-left: 35px">
                  <el-card style="width: 250px; height: 300px">
                      <div style="width: 210px;height: 210px">
                          <img :src="item.img" alt="" @click="open(item)" style="width: 100%;">
                      </div>
                      <div style="margin-top: 20px" >
                          <span style="font-size: 18px; float: left">{{ item.name }}</span>
                          <span style="font-size: 18px; color: red; float: right">￥{{ item.price }}</span>
                      </div>
                  </el-card>
              </el-col>
          </el-row>
      </div>-->

        <!-- 商品详情 -->
        <!--<el-dialog title="保险详情" custom-class="dialog" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
          <div style="margin-left: 5px">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="保险详情" name="first">
                      <div style="margin-top: 10px; margin-bottom: 10px">
                          {{ form.description }}
                      </div>
                      <hr style="background-color: rgba(196,196,196,0.33); border:none; height: 1px">
                      <div style="height: 20px; margin-top: 5px; margin-bottom: 5px">
                          <a style="float: left">保险期限</a>
                          <div style="margin-right: 20px;float: right">
                              {{ form.time }}
                          </div>
                      </div>
                      <hr style="background-color: rgba(196,196,196,0.33); border:none; height: 1px">
                      <div style="height: 20px; margin-top: 5px; margin-bottom: 5px">
                          <a style="margin-top: 10px">保险费用</a>
                          <div style="margin-right: 20px;float: right">
                              {{ form.price }}元
                          </div>
                      </div>
                  </el-tab-pane>
                  <el-tab-pane label="保险特色" name="second">
                      <img :src="form.characteristic" alt="" style="width: 100%;">
                  </el-tab-pane>
                  <el-tab-pane label="理赔流程" name="third">
                      <img :src="form.claims" alt="" style="width: 100%;">
                  </el-tab-pane>
              </el-tabs>
          </div>
          <div style="margin-top: 20px;margin-left: 600px"><el-button style="border-radius: 25px; height: 50px;width: 100px;font-size: 20px" type="primary" @click="buyInsurance(form.id)">购买</el-button></div>
      </el-dialog>-->
      

      <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" :destroy-on-close="true">
            <el-container>
                <el-header>
                    <div style="border-bottom:2px solid #000">
                        <span class="drawer_title">MY LINGFU</span>
                    </div>
                </el-header>
                <el-main>
                    <div>
                        <el-card v-for="item in tableData1" :key="item.id" class="drawer_card" :body-style="{ padding: '5px' }" shadow="hover">
                            <div style="display: flex">
                                <div style="width: 40%">
                                    <img class="drawer_img" :src="item.img">
                                </div>
                                <div style="width: 60%">
                                    <div style="display: flex">
                                        <div class="name_goods">
                                            <span class="drawer_name">{{ item.name }}</span>
                                        </div>
                                        <div class="refund_order">
                                            <i class="el-icon-close" v-if="item.state == '待支付'" @click="del(item.id)"></i>
                                        </div>
                                    </div>
                                    <div style="display:flex">
                                        <div style="width: 40%">
                                            <div class="mt10_h20">
                                                <div class="mt80_h20">
                                                    <span class="drawer_name">${{ item.total }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 60%;">
                                            <div style="display: flex">
                                                <div class="desc">
                                                    <span class="desc_info" v-if="item.state== '已支付'" @click="refundOrder(item)">退款</span>
                                                    <span class="desc_info" v-if="item.state== '已退款'" @click="refundsGet(item)">详情</span>
                                                </div>
                                                <div class="button_pay">
                                                    <el-button class="drawer_button" type="primary" v-if="item.state== '待支付'" @click="payPal(item)">GET</el-button>
                                                    <el-button class="drawer_button" type="primary" v-if="item.state== '已支付'">DETIAL</el-button>
                                                    <el-button class="drawer_button" type="primary" v-if="item.state== '已退款'">REBUY</el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-main>
                <el-footer class="height100">
                    <!--<div style="display:flex">
                        <div style="width: 50%">
                            <span class="drawer_name">T o t a l</span>
                        </div>
                        <div style="width: 50%; text-align:right">
                            <span class="drawer_name">${{ tableData.price }}</span>
                        </div>
                    </div>
                    <div style="">
                    </div>-->
                </el-footer>
            </el-container>
        </el-drawer>
    <!-- 购物车按钮 -->
        <div class="cart">
            <el-button class="cart_button"
            size="big" 
            circle
            @click="openDrawer()"
            >
                <i class="el-icon-shopping-cart-1"></i>
            </el-button>
        </div>

    </div>
</template>

<script>
import router, {setRoutes} from "@/router";
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入

export default {
  name: "FrontHome",
  components: {},
  data(){
    return{
        /*activeNames: ['-1'],
        imgs1: [
            'https://www.chinalife.com.cn/chinalife/resource/cms/2023/04/img_pc_site/2023041215482547911.jpg',
            'https://www.chinalife.com.cn/chinalife/resource/cms/2022/10/2022102712554748465.jpg',
            //require('../../assets/img1.png'),
        ],
        imgs2: [
            'https://tse1-mm.cn.bing.net/th/id/OIP-C.n0JA6GN4skcrkYfsBu4jsgHaEK?w=295&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7',
            'https://tse2-mm.cn.bing.net/th/id/OIP-C.KMh0t8-Q4H8QVD3lBXjKHgHaE8?pid=ImgDet&rs=1',
            //require('../../assets/img1.png'),
        ],
        notice: [],
        content: '',
        dialogFormVisible: false,
        name: '',
        pageNum: 1,
        pageSize: 4,
        price: 0,
        types: '',
        activeName: 'first',
        form: [],
*/

        tableData: [],
        msg: {
          name: '',
          sex:'',
          type:1,
          year:'',
          month:'',
          day:'',
          hour:'',
          minute:'',
          lang:'en-us',
        },
        form: {},
        drawer: false,
        tableData1: [],
        user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},

        //轮播图
        count: "", //倒计时
        swiper: null,
        swiper1: null,
        swiperList: [{
            id: 1,
            title: '',
            imgUrl: require("@/assets/home/Exorcism.png"),
        }, {
            id: 2,
            title: '',
            imgUrl: require("@/assets/home/Love.png"),
        }, {
            id: 3,
            title: '',
            imgUrl: require("@/assets/home/Wealth.png"),
        }, {
            id: 4,
            title: '',
            imgUrl: require("@/assets/home/Health.png"),
        }, {
            id: 5,
            title: '',
            imgUrl: require("@/assets/home/Studies.png"),
        }, {
            id: 6,
            title: '',
            imgUrl: require("@/assets/home/Luck.png"),
        }],
        
        name: "",
        price: 0,
        pageNum: 1,
        pageSize: 6,
        types: '',

    }
  },
  created() {
      this.load()
  },
  mounted() {
        this.getSwiper();
        this.getSwiper1();
    },
  methods: {
    load() {
            this.request.get("/insurance/page", {
                params: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    name: this.name,
                    price: this.price,
                    types: 'Houres_fengshui',
                    //types: this.types,
                }
            }).then(res => {
                this.tableData = res.data.records
                console.log(this.tableData);
            })
        },
    postData (image) {

        //符遮罩
        var modal = document.getElementById("modal");
        var modalImage = document.getElementById("modal-image");
        modal.style.display = "block";
        modalImage.src = image.src;

        //this.dialogTableVisible = true
        /* json格式提交： */
        let formData = JSON.stringify(this.msg);
        console.log(formData);

        this.request.post('/getForm', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res)=>{
            alert(res.data)
        }).catch(error=>{
            console.log(error);
        })

        this.request.get('/bazicesuan',{
        }).then((res)=>{
            //console.log(res.data)
            this.form = res.data.caiyun
        }).catch(error=>{
            console.log(error);
        })  
    },
    hideModal() {
        setTimeout(()=> {
            this.$router.push({path:'baziResult'});
        }, 500) 
    },
    getSwiper() {
        this.swiper = new Swiper(".swiper-container", {
            loop: true, // 无缝
            autoplay: { //自动开始
                delay: 1800, //时间间隔
                disableOnInteraction: false, //*手动操作轮播图后不会暂停*
            },
            paginationClickable: true,
            slidesPerView: 5, // 一组三个
            spaceBetween: 10, // 间隔
            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
            observer: true,
            observeParents: true,
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
                clickable: true, // 分页器可以点击
            },
        })
    },
    getSwiper1() {
        this.swiper1 = new Swiper(".swiper-container1", {
            loop: true, // 无缝
            autoplay: { //自动开始
                delay: 1800, //时间间隔
                disableOnInteraction: false, //*手动操作轮播图后不会暂停*
            },
            paginationClickable: true,
            slidesPerView: 5, // 一组三个
            spaceBetween: 12, // 间隔
            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
            observer: true,
            observeParents: true,
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
                clickable: true, // 分页器可以点击
            },
        })
    },
    addOrders() {
            this.request.get("/orders/getByUserId", {
                params: {
                    userId: this.user.id,
                }
            }).then(res => {
                this.tableData1 = res.data
                console.log(this.tableData1);
            })
    },
    payPal(row){
        /*this.request.post('/createOrder' , {
            "no": row.no,
        }).then(res => {
            this.approveUrl = res.approveUrl
            console.log(row.no);
            window.open(this.approveUrl)
        })*/
        this.request({
            url: '/createOrder',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                no: row.no,
                total: row.total,
                name: row.name
            }
        }).then((res)=> {
            this.approveUrl = res.approveUrl
            window.open(this.approveUrl)
        })
    },
    refundOrder(row) {
        this.$confirm('此操作将进行退款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.request({
                url: '/refundOrder',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    orderId: row.alipayNo,
                    price: row.total,
                    invoiceId: row.no
                }
            }).then((res)=> {
                this.$message.success("退款成功")
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消退款'
            });          
        });
    },
    refundsGet(row){
        console.log(row.refundId);
        this.request({
            url: '/refundsGet',
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                refundId: row.refundId,
            }
        }).then((res)=> {
            console.log(res);
        })
    },
    openDrawer() {
        this.addOrders();
        this.drawer = true;
    },
    



/*
      load() {
          this.request.get("/insurance/page1", {
              params: {
                  pageNum: this.pageNum,
                  pageSize: this.pageSize,
                  name: this.name,
                  price: this.price,
                  types: this.types,
              }
          }).then(res => {
              this.tableData = res.data.records
              console.log(this.tableData)
          })
      },
      open(item){
          this.dialogFormVisible = true
          this.form = item
      },
      handleClick(){
      },
*/
  }
}
</script>

<style scoped>
.lookf {
    width: 100%;
    padding: 70px 0;
}
.lookf_a {
        margin-bottom: 30px;
}
.swiper-slide {
    position: relative;
    height: 484px;
    width: 361px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #ffffff;
}
.banner_background {
    width: 100%;
    height: 36vw;
    background-image: url(../../assets/home/banner.png);
    background-position: top;
    -moz-background-size: 100% 100%;
    background-size:  100% 100%;
    margin-bottom: 100px;
}
.banner {
    width: 26%;
    height: 420px;
    margin-left: 38%;
}
.kun {
    height: 110px;
}
/*.banner_img {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index:0;
}*/
.banner_form {
    height: 600px;
}
.form1 {
    display: flex; 
    margin-top:8px;
    width: 288px;
}
.form_a {
    font-size: 2vw;
    font-family: 'Times New Roman', Times, serif;
    color: #F6D66F;
    letter-spacing: 4px;
} 
.input001 >>> .el-input__inner {
    font-size: 0.8vw;
    width: 288px; 
    height: 28px;
}
.input002 >>> .el-input__inner {
    font-size: 0.8vw;
    width: 4vw;
    height: 28px;
}
.input003 >>> .el-input__inner {
    font-size: 0.8vw;
    width: 4vw; 
    margin-left: 1vw;
    height: 28px;
}
.input004 >>> .el-input__inner {
    font-size: 0.8vw;
    width: 5vw; 
    margin-left: 1vw;
    height: 28px;
}
.input005 >>> .el-input__inner {
    font-size: 0.8vw;
    width: 7vw;
    height: 28px;
}
.input006 >>> .el-input__inner {
    font-size: 0.8vw;
    width: 7vw; 
    margin-left: 1vw;
    height: 28px;
}
.form_radio {
    font-size: 1.5vw;
    font-family: 'Times New Roman', Times, serif;
    color: #F6D66F;
    letter-spacing: 4px;
}
.form_button {
    font-size: 1.6vw;
    font-family: 'Times New Roman', Times, serif;
    color: #F6D66F;
    background-color: #623B7C;
    width: 15vw;
    height: 3vw;
    margin-top: 20px;
    border: 1px solid #F6D66F;
    border-radius: 5px;
    cursor: pointer;
}
.title_a70 {
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 10px;
}
.title_a40 {
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 10px;
}
.title_a {
    font-size: 60px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 10px;
}
.type1 {
    width: 20%;
    height: 641px;
    text-align: center;
}
.type1_img {
    width: 98%;
    height: 630px;
}
.type_a {
    position: absolute;
    width: 250px;
    height: 40px;
    z-indent: 2;
    background-color: #000; 
    color: #fff;
    font-size: 35px;
    font-family: 'Times New Roman', Times, serif;
}
.taoism {
    margin-top: 70px; 
    width: 60%; 
    margin-left: 20%;
}

.taoism_p {
    text-align: center;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
}
.taoism_img {
    height: 677px;
}
.maoshan {
    margin-top: 120px; 
    text-align:center; 
    height: 2150px;
}
.maoshan_img {
    position: absolute;
    top: -300px;
    left: 0;
    width: 100%;
    z-index: -1;
}
.maoshan_p {
    text-align: center;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 3px;
}
.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.603);
}

.modal-image {
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    margin-top: 2%;
}


/* 侧边购物车 */
.el-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}
.el-header {
    height: 40px !important;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.drawer_title {
    font-size: 30px;
}
.drawer_card {
    margin-top: 20px;
    margin-left: 1%;
    width: 98%;
    height: 190px;
    border: none;
    border-bottom: rgb(219, 219, 219) 1px solid;
    border-radius: 0;
}
.drawer_img {
    width: 170px;
}
.drawer_name {
    font-size: 20px;
}
.drawer_button {
    width: 100px;
    height: 35px;
    margin-top: 5px;
    font-size: 17px;
    background-color: #310052;
}
.desc {
    margin-top: 80px; 
    height: 20px;
    width: 30px;
    margin-left: 10%
}
.desc_info {
    font-size: 15px; 
    color:rgb(95, 95, 95); 
    cursor: pointer
}
.name_goods {
    display: flex;
    width: 280px;
    height: 60px;
}
.refund_order {
    margin-top: 5px; 
    height: 20px;
    text-align: right;
}
.button_pay {
    margin-top: 70px;
    margin-left: 20px; 
}
.height100 {
    height: 100px;
}
.mt10_h20 {
    margin-top: 10px; 
    height: 20px
}
.mt80_h20 {
    margin-top: 80px; 
    height: 20px
}
.cart {
    position: fixed;
    bottom: 50px;
    right: 100px;
    z-index: 0;
}
.cart_button {
    width: 60px; 
    height: 60px; 
    background-color: #310052;
    border: 1px sloa #fff;
    border-style: double;
}
.el-icon-shopping-cart-1 {
    font-size: 28px; 
    color: #fff
}




.foc {
    height: 750px;
    overflow: hidden;
}
.mtop80 {
    margin-top: 80px;
}
.mtop70 {
    margin-top: 70px;
}
.mtop60 {
    margin-top: 60px;
}
.mtop40 {
    margin-top: 40px;
}
.mtop30 {
    margin-top: 30px;
}
.ft30 {
    margin-top: 30px;
    display: flex;
}







/*.why-choose-contanir {
    width: 1148px;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 60px;
}
.why-choose-contanir h2 {
    margin-top: 60px;
    font-size: 35px;
    text-align: center;
    margin-bottom: 80px;
    color: #303234;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 50px;
}
.why-choose-imgbox {
    width: 120px;
    height: 120px;
    margin: 0 auto 52px;
}
.why-choose-imgbox img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.why-choose-textbox {
    width: 280px;
    text-align: center;
    margin: 0 auto;
}
.why-choose-textbox span {
    font-size: 14px;
    opacity: .8;
    color: #ffffff;
    letter-spacing: 1.75px;
}
.why-choose-textbox h4 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #303234;
    letter-spacing: 2px;
}
.why-choose-textbox p {
    margin-top: 14px;
    font-size: 16px;
    color: #494b4e;
    line-height: 26px;
    letter-spacing: 2px;
}
.why-choose-contanir .why-choose-items {
    width: 33.33%;
    float: left;
}
.clearfix:after, .layout:after, .clf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: '.';
    clear: both;
    height: 0;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: '.';
    clear: both;
    height: 0;
}
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: '.';
    clear: both;
    height: 0;
}
.clearfix:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

*/

</style>